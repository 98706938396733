@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=League+Spartan:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

:root{
  --primary-color-1: #c90909;
  --primary-color-2: #4176FF;
  --primary-color-3: #1C1C1C;

  --hyperlink-color: #7DA2FF;
  --default-width: 80vw;
  --footer-height: 35vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  min-height: 100vh;

  padding-bottom: var(--footer-height);
}

.content-container {
  display: flex;
  max-width: 100vw;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  width: var(--default-width);
  display: flex;
  flex-direction: column;
  gap: 13rem;
  padding-bottom: 10rem;
}

img{
  pointer-events: none;
}

/* ==================================================================================================================================== */
/* NAVIGATION AND FOOTER */

.nav-background {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-background-color-default {
  background: var(--primary-color-1);
}
.nav-background-color-dark {
  background: var(--primary-color-3);
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  min-width: 80vw;
  max-width: 80vw;

  padding: 1rem 0;
}
nav ul {
  display: flex;
  gap: 5rem;
  list-style: none;
}
nav a {
  color: white;
  font-family: 'Koulen', sans-serif;
  text-decoration: none;
  font-size: 1.5rem;
  transition: 0.2s;
}

nav a:hover{
  font-size: 1.6rem;
  transition: 0.2s;
}

nav a:active{
  font-size: 1.5rem;
  transition: 0.2s;
}

.nav-branding {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-branding:hover {
  cursor: pointer;
}

.nav-branding-image-logo {
  max-width: 3.5rem;
}

.nav-branding-logo-text {
  font-family: 'Koulen', sans-serif;
  font-size: 1.5rem;
  color: white;
  transition: 0.2s;
}

.nav-branding-logo-text:hover {
  font-size: 1.6rem;  
  transition: 0.2s;
}

.nav-branding-logo-text:active {
  font-size: 1.5rem;
  transition: 0.2s;
}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: var(--footer-height);

  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-background-color-default {
  background: var(--primary-color-1);
}
.footer-background-color-dark {
  background: var(--primary-color-3);
}
.footer-branding {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem; 
}
.footer-branding-image-logo {
  max-width: 10rem;
}
.footer-branding-logo-text {
  font-family: 'Koulen', sans-serif;
  color: white;
  font-size: 5rem;
}
.footer-copyright {
  position: absolute;
  bottom: 0;
  right: 1rem;

  color: white;
  font-family: 'Koulen', sans-serif;
  letter-spacing: 0.05rem;
}

/* ==================================================================================================================================== */
/* HERO */

.hero {
  /* specifiy width because content does not stretch a width of the whole screen */
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}
.hero-background { 
  background: var(--primary-color-1);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5rem 0;
}
.hero-content {

  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 80vw;
  max-width: 80vw;
}
.hero-content-text p {
  color: white;
  font-family: 'Koulen', sans-serif;
  font-size: 6rem;
  line-height: 7rem;
}
#hero-content-text-two {
  padding-left: 8rem;
}

.hero-content-image {
  max-width: 30rem;
  border-radius: 2rem;

  border: 0.15rem solid white;
}

/* ==================================================================================================================================== */
/* SECTION COMPONENT */

.section-container {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 6rem;
}
.section-image {
  max-width: 30rem;
  border-radius: 2rem;
  object-fit: cover;
  box-shadow: 0rem 1rem var(--primary-color-2);
}
.section-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 45vw;
}
.section-text h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 3.2rem;
  line-height: 4.6rem;
}
.section-text p {
  font-family: 'Lato', Courier, monospace;
  font-size: 1.3rem;
  line-height: 2.6rem;
  font-weight: 400;
}

/* ==================================================================================================================================== */
/* NEWSLETTER */

.newsletter-body {
  /*There is some weird spacing with the width of the newsletter page. Newsletter-body not the problem*/
  /*border: 2px solid blue;*/
  width: 100%;
  /* there should be no overflow when I comment out min-height. this is a bug that needs to be fixed */
  min-height: 230vh;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.newsletter-top {
  padding: 5rem 15rem;
  height: 40rem;
  position: relative;

  justify-content: center;
  align-items: center;
}

.newsletter-header {
  width: 70rem;
  line-height: 12rem;

  font-size: 12rem;
  font-weight: lighter;
  font-family: 'Koulen', sans-serif;
  color:rgb(180, 46, 46);
  -webkit-text-stroke: 0.4rem white;
}

.newsletter-description {
  width: 40rem;
  line-height: 3rem;

  font-size: 3rem;
  font-weight: lighter;
  font-family: 'Koulen', sans-serif;
  color:rgb(0, 0, 0);
}

.newsletter-mascot {
  width:43em;
  position: absolute;
  top: 1rem;
  left: 43rem;
  opacity: 0.3;
  z-index: -1;
}

.newsletter-content {
  height: 40rem;
  padding-top: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
}

.newsletter-subheader {
  font-size: 3rem;
  width: 40rem;
  color:rgb(54, 119, 211);
  font-weight: lighter;
  font-family: 'Koulen', sans-serif;

  display: flex;
  justify-content: center;
}

.newsletter-embed {
  width:40rem;
  height:40rem;

  outline: 0.2rem solid rgb(211, 54, 54); 
}

.newsletter-drive {
  flex-direction: column;
  gap:4rem;
}

/* .newsletter-instagram {
   I'm not sure how to make the instagram embed non-square; I'll have to look into the npm package (react-social-media-embed) later. 
} */

/* ==================================================================================================================================== */
/* QUOTE */

.large-quote {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  background: var(--primary-color-1);
  padding: 4rem 4rem 8rem 4rem;
}
.large-quote-quote {
  font-family: "Lilita One", sans-serif;
  font-size: 6rem;
  color: white;
}
.large-quote-author {
  font-family: "Lilita One", sans-serif;
  font-size: 3rem;
  color: white;
}

/* ==================================================================================================================================== */
/* ROBOT REVEAL */

.robot-reveal-hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  position: relative;
}
.robot-reveal-hero-text {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 30rem;
}
.robot-reveal-hero-text-title {
  font-family: 'League Spartan', sans-serif;
  font-size: 6rem;
  line-height: 7rem;
  font-weight: 700;
  color: var(--primary-color-2);
}
.robot-reveal-hero-text-description {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-weight: 700;
}
.robot-reveal-hero-image {
  max-width: 30rem;
  border-radius: 15rem;

  box-shadow: -1rem 0rem var(--primary-color-1);
}
.robot-reveal-hero-backdrop {
  position: absolute;
  bottom: 35vh;
  width: 100%;
  height: 50rem;
  background: var(--primary-color-1);
  opacity: 0.6;
  z-index: -1;
  object-fit: cover;

  /* editing property of the image by adding a linear gradient to it as if it were a background */
  /* https://educate.ceros.com/studio-6p6lqous/post/image-masking-TUGdAmdrEooDi4F */
  mask-image: linear-gradient(to bottom, transparent, white);
}

/* ==================================================================================================================================== */
/* SUPPORT US */

.support-container{
  background-color: var(--primary-color-1);
  display: column;
  justify-content: center;
}
.support-headline{
  padding-top: 3rem;
  margin-left: 10rem;
  margin-right: 20rem;
}
.support-header {
  font-size: 5.5rem;
  line-height: 8.5rem;
  font-family: 'Koulen', sans-serif;
  font-weight: 400;
  color: white;
  letter-spacing: 0.20rem;
}
.support-subheader {
  font-size: 2.5rem;
  font-family: 'Koulen', sans-serif;
  font-weight: 400;
  color: white;
  letter-spacing: 0.10rem;
  margin-bottom: 4rem;
}

.support-info{
  margin-top: 10rem;
  margin-left: 10rem;
  margin-bottom: 10rem;
}
.support-section-1{
  margin-right: 35rem;
  margin-bottom: 5rem;
}
.support-section-2{
  margin-bottom: 5rem;
  margin-left: 40rem;
  margin-right: 7.5rem;
}
.support-sponsors{
  margin: 0rem 10rem;
  padding-bottom: 4rem;
}

.support-sponsors-header {
  text-align: center;
  font-size: 6.6rem;
  line-height: 8.5rem;
  font-family: 'Koulen', sans-serif;
  font-weight: 400;
  color: white;
  letter-spacing: 0.20rem;
}

.support-text-1 {
  font-size: 3.75rem;
  font-family: 'Koulen', sans-serif;
  color: white;
}

.support-text-2 {
  font-size: 2.75rem;
  line-height: 4rem;
  font-family: 'League Spartan', sans-serif;
  color: white;
}

.support-tier {
  margin: 2rem 0rem 0rem 4rem;

  font-size: 5rem;
  font-family: 'League Spartan', sans-serif;
  color: white;
}

.support-sponsor-text {
  font-size: 3rem;
  font-family: 'Koulen', sans-serif;
  color: white;
  font-weight: normal;
}

.support-sponsor-tier-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.support-sponsor-tier{
  font-weight: normal;
}

.support-sponsor-tier-price{
  text-decoration: underline;
}

.support-tiergroup-gold{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10rem;
}

.support-gold-logo{
  height: 50vh;
}

/* 
  display: flex;
  flex-direction: row; */

.support-link {
  color: var(--hyperlink-color);
  text-decoration: none;
}
/* ==================================================================================================================================== */
/* ROBO SCOUTS LEGAL PAGE */

/* since className .legal p is below this selector, we need to decleare !important */
.header-main {
  font-size: 2.25rem !important;
  font-weight: 1000;
  font-family: 'Lato', Courier, monospace;
}

.header-small {
  font-size: 1.5rem !important;
}

.legal-container { 
  display: flex;
  justify-content: center;
}

.legal {
  max-width: 70rem;
  margin-top: 3rem;
  margin-bottom: 3rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
} 

.legal-hero {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.legal p, .legal-text {
  font-family: 'Lato', Courier, monospace;
  font-size: 1.15rem;
  line-height: 2rem;
}

.legal-definitions {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
}

.legal-definitions-interpretation, .legal-definitions-definitions {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
}

.legal-usage-data {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
}

.legal-usage-data-ul {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
  padding-left: 3rem;
}

.legal-usage-data-ul li {
  list-style-type: circle;
  font-size: 1.15rem;
}

.legal-usage-data-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.legal-contacts {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
}

@media (max-width: 40rem) {
  .legal {
    max-width: 20rem;
  }
}

/* ==================================================================================================================================== */
/* ABOUT */
.about-container {
  padding-top: 8rem;
  background-color: var(--cyberlions-white);
  display: flex;
  justify-content: center;
}

.about-content-header {
  min-width: var(--laptop-max-width);
}

.about-content-subtext {
  font-family: 'League Spartan', sans-serif;
  color: var(--cyberlions-gray);
  font-size: 1.25rem;
}

.about-content-header-text {
  max-width: 75rem;
  font-family: 'Koulen', sans-serif;
  color: var(--cyberlions-red);
  font-size: 3rem;
}

.about-content-text {
  font-family: 'Lato', Courier, monospace;
  font-size: 1.25rem;
  max-width: 75rem;
  margin-top: 1rem;
}

.about-subteam-container {
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  padding: 2rem;
  max-width: 80rem;
  border-radius: 0.75rem;
  background-color: #e9e9e9;
  width: 100%;
}

.about-subteam-selector {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1rem;
  min-width: 17rem;
}

.about-subteam-selector h1 {
  font-family: 'Lato', Courier, monospace;
  font-size: 1.5rem;
}

.about-subteam-selection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 1.5rem;
  transition: 0.2s;
}

.about-subteam-selection-button {
  font-family: 'Lato', Courier, monospace;
  cursor: pointer;
  list-style: none;
  font-size: 1rem;
}

.about-subteam-divider {
  background-color: #b0b0b0;
  width: 0.15rem;
}

.about-subteam-details {
  max-width: 40rem;
  transition: 0.2s;
}
.about-subteam-details p {
  font-family: 'Lato', Courier, monospace;
  font-size: 1.5rem;
  line-height: 2.5rem;
  transition: 0.2s;
}

.selected {
  font-size: 1.1rem;
  font-weight: 1000;
  color: var(--primary-color-1);
  transition: 0.2s;
}

.about-subteam-signup {
  margin: 10rem 0rem;
  display: flex;
  justify-content: space-between;
}

.about-subteam-signup-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-decoration: none;

  max-width: 35rem;
}

.about-subteam-signup-text h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 4rem;
  color: var(--primary-color-2)
}

.about-subteam-signup-text p {
  font-family: 'Lato', Courier, monospace;
  font-size: 2rem;
  line-height: 4rem;
}

.about-subteam-signup-text button {
  margin-top: 1rem;
  font-family: 'Lato', Courier, monospace;
  border-radius: 0.5rem;
  border: hidden;
  background-color: var(--primary-color-1);
  color: white;
  font-size: 1.25rem;
  max-width: 10rem;
  transition: 0.2s;

  /* do not specify a min-height as a % for the button when the parent container does not have a definite height. It will overflow due to logic */
  min-height: 4rem;
}

.about-subteam-signup-text button:hover {
  border: 0.2rem solid var(--primary-color-1);
  transition: 0.2s;
  cursor: pointer;
  background-color: white;
  color: var(--primary-color-1);
}

.about-subteam-signup img {
  max-width: 35rem;
  max-height: 35rem;
  border-radius: 1rem;
  object-fit: cover;
}

/* ==================================================================================================================================== */
/* ROBOT SHOWCASE */

.robot-showcase {
  position: relative;

  width: 100%;
  height: 95vh;
}
.robot-showcase-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  position: relative;
  z-index: -1;
}
.robot-showcase-year {
  position: absolute;
  top: 2rem;
  right: 10rem;

  color: white;
  font-family: 'Koulen', sans-serif;
  font-size: 3rem;
}
.robot-showcase-description {
  width: 18rem;
  position: absolute;
  bottom: 10rem;
  left: 5rem;
}
.robot-showcase-description::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-color-3);
  outline: 2rem solid var(--primary-color-3);
  opacity: 0.4;
  z-index: -1;
}
.robot-showcase-description-robot-name {
  color: white;
  font-family: 'Koulen', sans-serif;
  font-size: 4rem;
  line-height: 5rem;
}
.robot-showcase-description-specs-title {
  color: white;
  font-family: 'Koulen', sans-serif;
  font-size: 2rem;
}
.robot-showcase-description-specs-ul {
  list-style: none;
}
.robot-showcase-description-specs-li {
  color: white;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.5rem;
}